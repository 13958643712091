import React from 'react';
import { Route, Routes } from 'react-router';
export const renderRoutes = (routes) =>
  routes ? (
    <Routes>
      {routes.map((route, i) => (
        <Route
          exact={route.exact}
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          path={route.path}
          strict={route.strict}
          element={<route.component />}
        />
      ))}
    </Routes>
  ) : null;
