import TrainingMaterials from './Containers/TrainingMaterials';
import OfficialCrmIcons from './Containers/OfficialCrmIcons';
import Presentations from './Containers/Presentations';
import Onboarding from './Containers/Onboarding';

const IDHomepage = () => {
  const getDomainWithoutFirstSubdomain = () => {
    const domain = window.location.hostname;
    const domainParts = domain.split('.');
    return domainParts.slice(1).join('.');
  };

  window.location.href = `https://id.${getDomainWithoutFirstSubdomain()}`;
};

export const routesV1 = [
  {
    path: "/",
    exact: true,
    component: TrainingMaterials,
  },
  {
    path: "/training-materials",
    exact: true,
    component: TrainingMaterials,
  },
  {
    path: "/official-crm-icons",
    exact: true,
    component: OfficialCrmIcons,
  },
  {
    path: "/presentations",
    exact: true,
    component: Presentations,
  },
  {
    path: "/id-homepage",
    exact: true,
    component: IDHomepage,
  },
];

export const routesV2 = [
  {
    path: "/",
    exact: true,
    component: Onboarding,
  },
  {
    path: "/training-materials",
    exact: true,
    component: TrainingMaterials,
  },
  {
    path: "/official-crm-icons",
    exact: true,
    component: OfficialCrmIcons,
  },
  {
    path: "/presentations",
    exact: true,
    component: Presentations,
  },
  {
    path: "/id-homepage",
    exact: true,
    component: IDHomepage,
  },
  {
    path: "/onboarding",
    exact: true,
    component: Onboarding,
  },
];
