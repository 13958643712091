import React from 'react';
import { useLocation, useNavigate } from 'react-router';
export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
      <Component history={{ push: navigate }} location={location} {...props} />
    );
  };
  return Wrapper;
};
